.App-logo {
    width: 60px;
    height: 60px;
    position: fixed;
    bottom: 0%;
    transform: translate(-50%, -50px);
    left: calc(50%);
    opacity: 0;
}

.text {
    margin-left: 4px !important;
}

.section {
    position:absolute;
    text-align: left;
    transform: translate(-9%, -90%);
    top: 100%;
    left: 5%;
    height:75vh;
    width: 52vw;
}

.summary {
    width:35vw;
    display:inline-block;
    padding-top:30px;
    padding-left:8px;
}

.animatedText:hover {

    color: rgb(180, 97, 185);
}




@media (max-width:1200px) {
    .section {
        width: calc(100vw - 7%);
        text-align: left;
        transform: translate(0, -50%);
        top: 50%;
        left: 5%;
        height: auto;
    }
    .summary {
        width:50vw;
    }
}

@media (max-width:824px) {
    .summary {
        width: 80%;
        padding-top:30px;
    }
    .text {
        margin-bottom:-10px !important;
    }
}

.name {
    line-height: 10vh;
}
@media (max-width:510px) {
    .summary {
        width: calc(100vw - 14%);
        padding-top:20px;
    }
    .name {
        line-height:65px;
        margin-top:30px;
    }
    .text {
        margin-bottom:-20px !important;
    }
}