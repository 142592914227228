

.about {
    width: 45%;
    text-align: left;
    margin-left: 5%;
    opacity: 0;
}

.image {
    width:30vh;
    height:30vh;
    cursor:pointer;
    position:relative;
    z-index: 1000;
}


.triangle {
    display:flex;
    flex-wrap:wrap;
    width:100%;
    z-index:1000;
    position:relative
}

.aloneImg {
    width:100%;
    display:flex;
    justify-content: center;
    margin-top:100px;
}

.top {
    width:100%;
    display:flex;
    justify-content: space-between;
}

.topLeft {
    width:50%;
    margin-right:40px;
}
.topRight {
    width:50%;
    margin-left:40px
}


@media (max-width:900px) {
    .about {
        width: calc(100vw - 10%);
    }

}

