
.footnote {
    height:100vh;
    width:100vw;
    border-radius:100%;
    background:hsl(300, 2%, 7%);
    display:flex;
    justify-content: center;
    position:relative;
    left:50%;
    transform: translate(-50%,0);
    bottom:-60vh;
}

.logoFootnote {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
}

.logoFootnoteAlt {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
}

.logoFootnote:hover {
    cursor: pointer;
}




@media (max-width:900px){
    .footnote {
        width:900px;
    }
}