.track {
    width: 100%;
    height: 90%
}

.fadeIn {
    animation-name: fade;
    animation-duration: 1s;
}

.fadeOut {
    animation-name: fadeReverse;
    animation-duration: 1s;
}

@keyframes fade {
    0% {
        opacity: 0.2;
        scale: 0.5;
    }

    100% {
        opacity: 1;
        scale: 1
    }
}

@keyframes fadeReverse {
    0% {
        opacity: 1;
        scale: 1
    }
    100% {
        opacity: 0.2;
        scale: 0.5;
    }

    
}
