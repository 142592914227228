.App {
  text-align: center;
}
.panel {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  display:block;
}

