body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
  overflow-x:hidden;
}

html,body {
  scroll-behavior: smooth !important;
  scroll-snap-type: y mandatory;
}




.spinner {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 80px;
  height: 80px;
  stroke-dasharray: 294.72;
  stroke-dashoffset: 294.72;
  animation-name:draw;
  animation-duration: 4s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

@keyframes draw {
  100% {
    stroke-dashoffset: 0;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::-webkit-scrollbar {
  width: 0px;
}

::-webkit-scrollbar-thumb {
  background-color: #424242;
  border-radius:5px;
}

.mouse-follower {
  width:500px;
  height:500px;
  position:absolute;
  z-index: 10000;
  background: linear-gradient(45deg,rgb(44, 77, 197),rgb(209, 158, 212));
  border-radius:50%;
  filter: blur(100px);
  opacity:0.5;
  pointer-events: none;
}